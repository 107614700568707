<template>
  <div class="orderSubmit">
    <img src="../../assets/submitEnding/post_success_result.png" alt />
    <h1>您的订单已提交成功</h1>
    <p class="goOrderCenter" @click="gopro">点击前往订单中心</p>
    <div style="height:16px;"></div>
    <div class="copyOrder" v-for="(item,index) in orderListItem" :key="index">
      <p class="copyName">{{orderName || '订单号'}}</p>
      <p>{{item}}</p>
      <p class="orderCopy" :data-clipboard-text="item" @click="copyOrder">复制</p>
    </div>
    <div class="btn">
      <div @click="gopro">{{returnname?returnname:'返回列表'}}</div>
      <div class="returnCar" @click="goCar">返回首页</div>
    </div>
  </div>
</template>


<script>
import Clipboard from "clipboard";
export default {
  name: "ordersubmit",
  data() {
    return {
      orderListItem: [],
      orderName: "",
      returnpath: "",
      returnname: "",
      orderRoute: {}
    };
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      vm.returnname = from.meta.titles;
    });
  },
  mounted() {
    var orderList = this.$route.query;
    this.orderRoute = orderList;
    this.orderName = orderList.orgName;
    if (orderList.b2bOrderCode.split(",")) {
      this.orderListItem = orderList.b2bOrderCode.split(",");
    } else {
      this.orderListItem = orderList.b2bOrderCode;
    }
  },
  methods: {
    // 橱窗列表
    gopro() {
      if (this.orderRoute.channel == 1) {
        this.$router.push("/order/dms");
      }else if(this.orderRoute.channel == 2) {
        this.$router.push("/channelOrder/orderList");
      }  else {
        this.$router.push("/order/cis");
      }
    },
    //   点击返回购物车
    goCar() {
      this.$router.push("/index");
    },
    // 继续购物
    goBuy() {
      this.$router.go("-3");
    },
    copyOrder() {
      var clipboard = new Clipboard(".orderCopy");
      clipboard.on("success", e => {
        this.$message.success("复制成功");
        // alert('复制成功')
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制
        this.$message.warning("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    }
  }
};
</script>
<style lang='less' scoped>
.orderSubmit {
  width: 1188px;
  //   height: 492px;
  padding-bottom: 70px;
  background: #fff;
  margin: 16px auto;
  .goOrderCenter {
    cursor: pointer;
    font-family: PingFang-SC-Medium;
    font-size: 16px;
    color: #00aaa6;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
    margin-top: 8px;
  }
  img {
    margin-top: 70px;
  }
  h1 {
    font-family: PingFangSC-Medium;
    font-size: 20px;
    color: #262626;
    letter-spacing: 0;
    text-align: center;
    line-height: 26px;
    margin-top: 24px;
  }
  .copyOrder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 390px;
    padding: 12px 24px;
    box-sizing: border-box;
    background: #f7f7f7;
    margin: 0 auto;
    p {
      width: 30%;
      // margin: 16px auto 0;
      font-size: 14px;
      color: #262626;
      line-height: 20px;
      margin-top: 0;
    }
    .orderCopy {
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1890ff;
    }
  }
  //   按钮
  .btn {
    width: 390px;
    margin: 40px auto 0;
    display: flex;
    justify-content: center;
    div {
      width: 118px;
      height: 40px;
      cursor: pointer;
      text-align: center;
      line-height: 40px;
      background: #00AAA6;
      color: #fff;
      margin-right: 20px;
      //border-radius: 4px;
    }
    .returnCar {
      background: #ffffff;
      border: 1px solid #00AAA6;
      width: 116px;
      color: #00AAA6;
    }
  }
}
</style>